/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Draggable from 'react-draggable';
import styles from './style.module.scss';

// Window styles
const Window = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMaximized' && prop !== 'isMinimized'
})<{ isMaximized: boolean; isMinimized: boolean; maxWidth: string }>(({ theme, isMaximized, isMinimized, maxWidth }) => ({
    borderRadius: 8,
    border: '1px solid #bbb',
    boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
    background: '#eee',
    display: isMinimized ? 'none' : 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'absolute',
    opacity: 1,
    height: isMaximized ? 'calc(100vh)' : 'auto', // Subtracting some height for browser UI
    width: isMaximized ? maxWidth : '600px',
    maxHeight: isMaximized ? 'calc(100vh)' : '600px', // Same here
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
        width: isMaximized ? '100vw' : '80vw',
        height: isMaximized ? 'calc(100vh - 60px)' : '80vh'
    }
}));

const WindowBar = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    padding: '5px 10px',
    cursor: 'grab'
}));

// Custom scroll styles for WindowContent
const WindowContent = styled(Card)(({ theme }) => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch', // Smooth scrolling for mobile
    height: '100%',
    paddingBottom: '0px', // Adding bottom padding for visibility
    '&::-webkit-scrollbar': {
        width: '8px' // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1', // Background of the track
        borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // Scrollbar color
        borderRadius: '10px',
        border: '2px solid #f1f1f1' // Padding around the scrollbar thumb
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555' // Scrollbar color on hover
    },
    [theme.breakpoints.down('sm')]: {
        padding: '5px'
    }
}));

const MacOSButton = styled(Box)<{ color: string }>(({ color }) => ({
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: color,
    margin: '0 5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s',
    fontSize: 12,
    color: '#fff',
    '&:hover': {
        backgroundColor: `${color}99` // Slightly transparent on hover
    }
}));

interface MacOSWindowProps {
    title: string;
    children: React.ReactNode;
    id: string;
    onFocus: (id: string) => void;
    isActive: boolean;
    onClose: (id: string) => void;
    onMinimize: (id: string) => void;
    onRestore: (id: string) => void;
    isMinimized: boolean;
    defaultMaximize?: boolean;
    maxWidth?: string;
}
const MacOSWindow: React.FC<MacOSWindowProps> = ({
    title,
    children,
    id,
    onFocus,
    isActive,
    onClose,
    onMinimize,
    onRestore,
    isMinimized,
    defaultMaximize = false,
    maxWidth = '100vw'
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isMaximized, setIsMaximized] = useState(defaultMaximize);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleClose = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        setIsVisible(false);
        onClose(id);
    };

    const handleMinimize = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        onMinimize(id);
    };

    const handleRestore = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        setIsMaximized(false);
        onRestore(id);
    };

    const handleMaximize = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        setIsMaximized(true);
        setPosition({ x: 0, y: 0 });
    };

    const calculateCenterPosition = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const windowWidth = isMaximized ? width : width < 600 ? width * 0.8 : 600;
        const windowHeight = isMaximized ? height : width < 600 ? height * 0.8 : 600;

        return {
            x: (width - windowWidth) / 2,
            y: (height - windowHeight) / 2
        };
    };

    useEffect(() => {
        if (!isMaximized) {
            setPosition(calculateCenterPosition());
        }

        const handleResize = () => {
            setPosition(calculateCenterPosition());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMaximized]);

    if (!isVisible) return null;

    return (
        <Draggable
            handle=".draggable-window-bar"
            bounds="parent"
            position={isMaximized && maxWidth === '100vw' ? { x: 0, y: 0 } : position}
            onMouseDown={() => onFocus(id)}
            cancel=".button"
            onDrag={(e, data) => {
                if (!isMaximized || maxWidth !== '100vw') {
                    setPosition({ x: data.x, y: data.y });
                }
            }}
        >
            <Window
                isMaximized={isMaximized}
                isMinimized={isMinimized}
                maxWidth={maxWidth}
                className={isMinimized ? styles.windowclosed : styles.windowopen}
                sx={{
                    zIndex: isActive ? 9999 : id,
                    pointerEvents: isMinimized ? 'none' : 'auto',
                    position: isMaximized ? 'fixed' : 'absolute',
                    top: isMaximized ? 0 : 'initial',
                    left: isMaximized ? 0 : 'initial',
                    transform: isMaximized ? 'none' : `translate(${position.x}px, ${position.y}px)`,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.05s ease', // Smooth transformation
                    '&:hover': {
                        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' // Hover effect
                    }
                }}
            >
                <WindowBar className="draggable-window-bar">
                    <Typography fontSize={15} sx={{ position: 'absolute', fontWeight: 'bold' }} align="center" variant="caption">
                        {title}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <MacOSButton color="#FFBD2E" onTouchEnd={handleMinimize} onClick={handleMinimize}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none">
                                <rect x="5" y="11" width="14" height="2" fill="none" stroke="white" strokeWidth="2" />
                            </svg>
                        </MacOSButton>
                        <MacOSButton
                            color="#28C93F"
                            onTouchEnd={isMaximized ? handleRestore : handleMaximize}
                            onClick={isMaximized ? handleRestore : handleMaximize}
                        >
                            {isMaximized ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none">
                                    <path d="M8 8H20V20H8V8Z" fill="none" stroke="white" strokeWidth="2" />
                                    <path d="M4 4H16V16H4V4Z" fill="none" stroke="white" strokeWidth="2" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="6" width="12" height="12" fill="none" stroke="white" strokeWidth="2" />
                                </svg>
                            )}
                        </MacOSButton>
                        <MacOSButton color="#FF5F56" onTouchEnd={handleClose} onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none">
                                <line x1="6" y1="6" x2="18" y2="18" stroke="white" strokeWidth="2" />
                                <line x1="6" y1="18" x2="18" y2="6" stroke="white" strokeWidth="2" />
                            </svg>
                        </MacOSButton>
                    </Box>

                    <Box />
                </WindowBar>

                <WindowContent>{children}</WindowContent>
            </Window>
        </Draggable>
    );
};

export default MacOSWindow;
